import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { customAreas } from '@bridebook/toolbox/src/search-suppliers/custom-areas';
import { capitalizeFirstLetter } from '@bridebook/toolbox/src/utils/strings';
import { CustomAutocompletePrediction } from 'app-shared/lib/search/types';

const getCustomUKPredictions = (): CustomAutocompletePrediction[] =>
  customAreas.map((customArea) => {
    const prediction: CustomAutocompletePrediction = {
      area: customArea.name,
      mainText: customArea.name,
      secondaryText: customArea.subtext || 'UK',
      id: customArea.id,
      place_id: customArea.place_id || '',
      custom: true as const,
      address: {
        adminArea: [customArea.county, capitalizeFirstLetter(customArea.country)],
        name: customArea.name,
        country: CountryCodes.GB,
      },
    };
    return prediction;
  });

export const customPredictions: { [K in CountryCodes as string]?: CustomAutocompletePrediction[] } =
  {
    [CountryCodes.GB]: [
      // LIVE-17083 suggest when typing london - London first, custom London areas, Greater London in the end
      {
        place_id: 'ChIJdd4hrwug2EcRmSrV3Vo6llI',
        area: 'London',
        mainText: 'London',
        secondaryText: 'UK',
        id: 'london-uk',
        custom: true,
        address: {
          adminArea: ['Greater London', 'England'],
          country: CountryCodes.GB,
          city: 'London',
          name: 'London',
        },
      },
      {
        place_id: 'ChIJb-IaoQug2EcRi-m4hONz8S8',
        area: 'Greater London',
        mainText: 'Greater London',
        secondaryText: 'Inside the M25, UK',
        id: 'greater-london-uk',
        custom: true,
        replaceByMainText: true,
        address: {
          adminArea: ['Greater London', 'England'],
          country: CountryCodes.GB,
          name: 'Greater London',
        },
      },
      ...getCustomUKPredictions(),
      {
        place_id: 'ChIJ39UebIqp0EcRqI4tMyWV4fQ|sussex',
        area: 'Sussex',
        mainText: 'Sussex',
        secondaryText: 'UK',
        id: 'sussex-uk',
        custom: true,
        replaceByMainText: true,
        address: {
          adminArea: ['Sussex', 'England'],
          country: CountryCodes.GB,
          name: 'Sussex',
        },
      },
      {
        place_id: 'ChIJ7Q8cbLY0ZEgRouilirxxux4',
        area: 'Wales',
        mainText: 'Wales',
        secondaryText: 'UK',
        id: 'wales-uk',
        custom: true,
        address: {
          adminArea: ['Wales', 'Wales'],
          country: CountryCodes.GB,
          name: 'Wales',
        },
        replaceByMainText: true,
        matchingRule: {
          minLength: 2,
          keywords: ['wales'],
        },
      },
      {
        place_id: 'ChIJCScgcLZBZUgRsQ66qkB99oc',
        area: 'North Wales',
        mainText: 'North Wales',
        secondaryText: 'UK',
        id: 'north-wales-uk',
        custom: true,
        address: {
          adminArea: ['North Wales', 'Wales'],
          country: CountryCodes.GB,
          name: 'North Wales',
        },
        matchingRule: {
          minLength: 2,
          keywords: ['wales'],
        },
      },
      {
        place_id: 'ChIJe5PC2088bkgR4HzQsbyQTWs',
        area: 'South Wales',
        mainText: 'South Wales',
        secondaryText: 'UK',
        id: 'south-wales-uk',
        custom: true,
        address: {
          adminArea: ['South Wales', 'Wales'],
          country: CountryCodes.GB,
          name: 'South Wales',
        },
        matchingRule: {
          minLength: 2,
          keywords: ['wales'],
        },
      },
      {
        place_id: 'ChIJ7Q8cbLY0ZEgRouilirxxux4|north-west-wales',
        area: 'North West Wales',
        mainText: 'North West Wales',
        secondaryText: 'UK',
        id: 'north-west-wales-uk',
        custom: true,
        address: {
          adminArea: ['North West Wales', 'Wales'],
          country: CountryCodes.GB,
          name: 'North West Wales',
        },
        matchingRule: {
          minLength: 2,
          keywords: ['wales'],
        },
      },
      {
        place_id: 'ChIJ7Q8cbLY0ZEgRouilirxxux4|north-east-wales',
        area: 'North East Wales',
        mainText: 'North East Wales',
        secondaryText: 'UK',
        id: 'north-east-wales-uk',
        custom: true,
        address: {
          adminArea: ['North East Wales', 'Wales'],
          country: CountryCodes.GB,
          name: 'North East Wales',
        },
        matchingRule: {
          minLength: 2,
          keywords: ['wales'],
        },
      },
      {
        place_id: 'ChIJ7Q8cbLY0ZEgRouilirxxux4|cardiff-vale-of-glamorgan-and-newport',
        area: 'Cardiff, Vale of Glamorgan & Newport',
        mainText: 'Cardiff, Vale of Glamorgan & Newport',
        secondaryText: 'UK',
        id: 'cardiff-and-the-vale-newport-uk',
        custom: true,
        address: {
          adminArea: ['Cardiff, Vale of Glamorgan & Newport', 'Wales'],
          country: CountryCodes.GB,
          name: 'Cardiff, Vale of Glamorgan & Newport',
        },
        matchingRule: {
          minLength: 2,
          keywords: ['wales'],
        },
      },
      {
        place_id: 'ChIJ7Q8cbLY0ZEgRouilirxxux4|swansea-port-talbot-and-bridgend',
        area: 'Swansea, Port Talbot & Bridgend',
        mainText: 'Swansea, Port Talbot & Bridgend',
        secondaryText: 'UK',
        id: 'swansea-port-talbot-and-bridgend-uk',
        custom: true,
        address: {
          adminArea: ['Swansea, Port Talbot & Bridgend', 'Wales'],
          country: CountryCodes.GB,
          name: 'Swansea, Port Talbot & Bridgend',
        },
        matchingRule: {
          minLength: 2,
          keywords: ['wales'],
        },
      },
      {
        place_id: 'ChIJ7Q8cbLY0ZEgRouilirxxux4|mid-wales',
        area: 'Mid Wales',
        mainText: 'Mid Wales',
        secondaryText: 'UK',
        id: 'mid-wales-uk',
        custom: true,
        address: {
          adminArea: ['Mid Wales', 'Wales'],
          country: CountryCodes.GB,
          name: 'Mid Wales',
        },
        matchingRule: {
          minLength: 2,
          keywords: ['wales'],
        },
      },
      {
        place_id: 'ChIJ7Q8cbLY0ZEgRouilirxxux4|west-wales',
        area: 'West Wales',
        mainText: 'West Wales',
        secondaryText: 'UK',
        id: 'west-wales-uk',
        custom: true,
        address: {
          adminArea: ['West Wales', 'Wales'],
          country: CountryCodes.GB,
          name: 'West Wales',
        },
        matchingRule: {
          minLength: 2,
          keywords: ['wales'],
        },
      },
      {
        place_id: 'ChIJ7Q8cbLY0ZEgRouilirxxux4|the-valleys',
        area: 'The Valleys',
        mainText: 'The Valleys',
        secondaryText: 'UK',
        id: 'the-valleys-uk',
        custom: true,
        address: {
          adminArea: ['The Valleys', 'Wales'],
          country: CountryCodes.GB,
          name: 'The Valleys',
        },
        matchingRule: {
          minLength: 2,
          keywords: ['wales'],
        },
      },
      {
        place_id: 'ChIJIyaYpQC4h0gRJxfnfHsU8mQ',
        area: 'Edinburgh',
        mainText: 'Edinburgh',
        secondaryText: 'City, UK',
        id: 'edinburgh-uk',
        custom: true,
        replaceByMainText: true,
        address: {
          adminArea: ['City of Edinburgh', 'Scotland'],
          country: CountryCodes.GB,
          name: 'Edinburgh',
        },
        matchingRule: {
          minLength: 2,
          keywords: ['edinburgh'],
        },
      },
      {
        place_id: 'ChIJUXnOUJTGh0gRwLNCBhpoDAM',
        area: 'City of Edinburgh',
        mainText: 'City of Edinburgh',
        secondaryText: 'County, UK',
        id: 'city-of-edinburgh-uk',
        custom: true,
        replaceByMainText: true,
        address: {
          adminArea: ['City of Edinburgh', 'Scotland'],
          country: CountryCodes.GB,
          name: 'City of Edinburgh',
        },
        matchingRule: {
          minLength: 2,
          keywords: ['edinburgh'],
        },
      },
      {
        place_id: 'ChIJE5-LOrccbkgRzfLvM7ow5xU',
        area: 'Cardiff City',
        mainText: 'Cardiff City',
        secondaryText: 'UK',
        id: 'cardiff-city-uk',
        custom: true,
        replaceByMainText: true,
        address: {
          adminArea: ['Cardiff City', 'Wales'],
          country: CountryCodes.GB,
          name: 'Cardiff City',
        },
        matchingRule: {
          minLength: 2,
          keywords: ['cardiff'],
        },
      },
      {
        place_id: 'ChIJ9VPsNNQCbkgRDmeGZdsGNBQ',
        area: 'Cardiff',
        mainText: 'Cardiff',
        secondaryText: 'UK',
        id: 'cardiff-county-uk',
        custom: true,
        replaceByMainText: true,
        address: {
          adminArea: ['Cardiff', 'Wales'],
          country: CountryCodes.GB,
          name: 'Cardiff',
        },
        matchingRule: {
          minLength: 2,
          keywords: ['cardiff'],
        },
      },
    ],
    [CountryCodes.DE]: [
      {
        place_id: 'ChIJ8_KccStOqEcRhtFXjKWPuo0',
        area: 'berlin--berlin--de',
        mainText: 'Berlin',
        secondaryText: 'Berlin, Bundesland',
        id: 'berlin-bundesland-de',
        custom: true,
        replaceByMainText: true,
        address: {
          adminArea: ['Berlin', 'Berlin'],
          country: CountryCodes.DE,
          city: 'Berlin',
          name: 'Berlin',
        },
      },
      {
        place_id: 'ChIJAVkDPzdOqEcRcDteW0YgIQQ',
        area: 'berlin-mitte--berlin--de',
        mainText: 'Berlin-Mitte',
        secondaryText: 'Berlin, Stadt',
        id: 'berlin-stadt-de',
        custom: true,
        replaceByMainText: true,
        address: {
          adminArea: ['Berlin-Mitte', 'Berlin'],
          country: CountryCodes.DE,
          city: 'Berlin-Mitte',
          name: 'Berlin',
        },
      },
      {
        place_id: 'ChIJuRMYfoNhsUcR8HjYe_I9JgE',
        area: 'hamburg--hamburg--de',
        mainText: 'Hamburg',
        secondaryText: 'Hamburg, Bundesland',
        id: 'hamburg-bundesland-de',
        custom: true,
        replaceByMainText: true,
        address: {
          adminArea: ['Hamburg', 'Hamburg'],
          country: CountryCodes.DE,
          city: 'Hamburg',
          name: 'Hamburg',
        },
      },
      {
        place_id: 'ChIJuRMYfoNhsUcRoDrWe_I9JgQ',
        area: 'hamburg-mitte--hamburg--de',
        mainText: 'Hamburg-Mitte',
        secondaryText: 'Hamburg, Stadt',
        id: 'hamburg-stadt-de',
        custom: true,
        replaceByMainText: true,
        address: {
          adminArea: ['Hamburg-Mitte', 'Hamburg'],
          country: CountryCodes.DE,
          city: 'Hamburg-Mitte',
          name: 'Hamburg',
        },
      },
      {
        place_id: 'ChIJNePuDBAosUcRbkKsojcptUI',
        area: 'bremen--bremen--de',
        mainText: 'Bremen',
        secondaryText: 'Bremen, Bundesland',
        id: 'bremen-bundesland-de',
        custom: true,
        replaceByMainText: true,
        address: {
          adminArea: ['Bremen', 'Bremen'],
          country: CountryCodes.DE,
          city: 'Bremen',
          name: 'Bremen',
        },
      },
      {
        place_id: 'ChIJNePuDBAosUcRUd83-VyI6MI',
        area: 'bremen-mitte--bremen--de',
        mainText: 'Bremen-Mitte',
        secondaryText: 'Bremen, Stadt',
        id: 'bremen-stadt-de',
        custom: true,
        replaceByMainText: true,
        address: {
          adminArea: ['Bremen-Mitte', 'Bremen'],
          country: CountryCodes.DE,
          city: 'Bremen-Mitte',
          name: 'Bremen',
        },
      },
      {
        place_id: '',
        area: 'spreewald--brandenburg--de',
        mainText: 'Spreewald',
        secondaryText: 'Brandenburg, Deutschland',
        id: 'spreewald-de',
        custom: true,
        address: {
          adminArea: ['Spreewald', 'Brandenburg'],
          country: CountryCodes.DE,
          name: 'Spreewald',
        },
      },
      {
        place_id: '',
        area: 'ostsee--norddeutschland--de',
        mainText: 'Ostsee',
        secondaryText: 'Ostsee, Deutschland',
        id: 'ostsee-de',
        custom: true,
        address: {
          adminArea: ['Ostsee', 'Norddeutschland'],
          country: CountryCodes.DE,
          name: 'Ostsee',
        },
      },
      {
        place_id: 'ChIJGddxFT9tdkcRYKc3CaQlHQQ',
        area: 'chiemsee--bayern--de',
        mainText: 'Chiemsee',
        secondaryText: 'Bayern, Deutschland',
        id: 'chiemsee-de',
        custom: true,
        address: {
          adminArea: ['Chiemsee', 'Bayern'],
          country: CountryCodes.DE,
          name: 'Chiemsee',
        },
      },
      {
        place_id: '',
        area: 'schwarzwald--baden-württemberg--de',
        mainText: 'Schwarzwald',
        secondaryText: 'Baden-Württemberg, Deutschland',
        id: 'schwarzwald-de',
        custom: true,
        address: {
          adminArea: ['Schwarzwald', 'Baden-Württemberg'],
          country: CountryCodes.DE,
          name: 'Schwarzwald',
        },
      },
      {
        place_id: '',
        area: 'allgäu--bayern--de',
        mainText: 'Allgäu',
        secondaryText: 'Bayern, Deutschland',
        id: 'allgau-de',
        custom: true,
        address: {
          adminArea: ['Allgäu', 'Bayern'],
          country: CountryCodes.DE,
          name: 'Allgäu',
        },
        matchingRule: {
          minLength: 3,
          keywords: ['allgaeu', 'allgau', 'allgäu'],
        },
      },
      {
        place_id: '',
        area: 'lüneburger-heide--niedersachsen--de',
        mainText: 'Lüneburger Heide',
        secondaryText: 'Niedersachsen, Deutschland',
        id: 'luneburger-heide-de',
        custom: true,
        address: {
          adminArea: ['Lüneburger Heide', 'Niedersachsen'],
          country: CountryCodes.DE,
          name: 'Lüneburger Heide',
        },
        matchingRule: {
          minLength: 2,
          keywords: [
            'lune',
            'luene',
            'lüne',
            'lueneburger heide',
            'luneburger heide',
            'lüneburger heide',
          ],
        },
      },
      {
        place_id: '',
        area: 'erzgebirge--sachsen--de',
        mainText: 'Erzgebirge',
        secondaryText: 'Sachsen, Deutschland',
        id: 'erzgebirge-de',
        custom: true,
        address: {
          adminArea: ['Erzgebirge', 'Sachsen'],
          country: CountryCodes.DE,
          name: 'Erzgebirge',
        },
      },
      {
        place_id: '',
        area: 'taunus--hesse--de',
        mainText: 'Taunus',
        secondaryText: 'Hesse, Deutschland',
        id: 'taunus-de',
        custom: true,
        address: {
          adminArea: ['Taunus', 'Hesse'],
          country: CountryCodes.DE,
          name: 'Taunus',
        },
      },
      {
        place_id: '',
        area: 'nordsee--norddeutschland--de',
        mainText: 'Nordsee',
        secondaryText: 'Nordsee, Norddeutschland',
        id: 'nordsee-de',
        custom: true,
        address: {
          adminArea: ['Nordsee', 'Norddeutschland'],
          country: CountryCodes.DE,
          name: 'Nordsee',
        },
      },
      {
        place_id: '',
        area: 'rheinland--nordrhein-westfalen--de',
        mainText: 'Rheinland',
        secondaryText: 'Rhineland, Deutschland',
        id: 'rheinland-de',
        custom: true,
        address: {
          adminArea: ['Rheinland', 'Nordrhein-Westfalen'],
          country: CountryCodes.DE,
          name: 'Rheinland',
        },
      },
      {
        place_id: '',
        area: 'eifel--rheinland-pfalz--de',
        mainText: 'Eifel',
        secondaryText: 'Rheinland-Pfalz, Deutschland',
        id: 'eifel-de',
        custom: true,
        address: {
          adminArea: ['Eifel', 'Rheinland-Pfalz'],
          country: CountryCodes.DE,
          name: 'Eifel',
        },
      },
      {
        place_id: '',
        area: 'altes-land--niedersachsen--de',
        mainText: 'Altes Land',
        secondaryText: 'Niedersachsen, Deutschland',
        id: 'altes-land-de',
        custom: true,
        address: {
          adminArea: ['Altes Land', 'Niedersachsen'],
          country: CountryCodes.DE,
          name: 'Altes Land',
        },
      },
      {
        place_id: '',
        area: 'niederrhein--nordrhein-westfalen--de',
        mainText: 'Niederrhein',
        secondaryText: 'Nordrhein-Westfalen, Deutschland',
        id: 'niederrhein-de',
        custom: true,
        address: {
          adminArea: ['Niederrhein', 'Nordrhein-Westfalen'],
          country: CountryCodes.DE,
          name: 'Niederrhein',
        },
      },
      {
        place_id: '',
        area: 'fränkische-schweiz--bayern--de',
        mainText: 'Fränkische Schweiz',
        secondaryText: 'Bayern, Deutschland',
        id: 'frankische-schweiz-de',
        custom: true,
        address: {
          adminArea: ['Fränkische Schweiz', 'Bayern'],
          country: CountryCodes.DE,
          name: 'Fränkische Schweiz',
        },
        matchingRule: {
          minLength: 2,
          keywords: [
            'frank',
            'fraenk',
            'fränk',
            'fränkische schweiz',
            'fraenkische schweiz',
            'frankische schweiz',
          ],
        },
      },
      {
        place_id: '',
        area: 'kaiserstuhl--baden-württemberg--de',
        mainText: 'Kaiserstuhl',
        secondaryText: 'Baden-Württemberg, Deutschland',
        id: 'kaiserstuhl-de',
        custom: true,
        address: {
          adminArea: ['Kaiserstuhl', 'Baden-Württemberg'],
          country: CountryCodes.DE,
          name: 'Kaiserstuhl',
        },
      },
      {
        place_id: '',
        area: 'sächsische-schweiz--sachsen--de',
        mainText: 'Sächsische Schweiz',
        secondaryText: 'Sachsen, Deutschland',
        id: 'sachsische-schweiz-de',
        custom: true,
        address: {
          adminArea: ['Sächsische Schweiz', 'Sachsen'],
          country: CountryCodes.DE,
          name: 'Sächsische Schweiz',
        },
        matchingRule: {
          minLength: 3,
          keywords: [
            'sachs',
            'saechs',
            'saechsische schweiz',
            'sächsische schweiz',
            'sachsische schweiz',
          ],
        },
      },
      {
        place_id: '',
        area: 'hunsrück--rheinland-pfalz--de',
        mainText: 'Hunsrück',
        secondaryText: 'Rheinland-Pfalz, Deutschland',
        id: 'hunsruck-de',
        custom: true,
        address: {
          adminArea: ['Hunsrück', 'Rheinland-Pfalz'],
          country: CountryCodes.DE,
          name: 'Hunsrück',
        },
        matchingRule: {
          minLength: 2,
          keywords: ['hunsrück', 'hunsruck', 'hunsrueck'],
        },
      },
      {
        place_id: '',
        area: 'siebengebirge--nordrhein-westfalen--de',
        mainText: 'Siebengebirge',
        secondaryText: 'Siebengebirge, Deutschland',
        id: 'siebengebirge-de',
        custom: true,
        address: {
          adminArea: ['Siebengebirge', 'Nordrhein-Westfalen'],
          country: CountryCodes.DE,
          name: 'Siebengebirge',
        },
      },
      {
        place_id: '',
        area: 'vogtland--sachsen--de',
        mainText: 'Vogtland',
        secondaryText: 'Sachsen, Deutschland',
        id: 'vogtland-de',
        custom: true,
        address: {
          adminArea: ['Vogtland', 'Sachsen'],
          country: CountryCodes.DE,
          name: 'Vogtland',
        },
      },
      {
        place_id: '',
        area: 'mittelrhein--rheinland-pfalz--de',
        mainText: 'Mittelrhein',
        secondaryText: 'Rheinland-Pfalz, Deutschland',
        id: 'mittelrhein-de',
        custom: true,
        address: {
          adminArea: ['Mittelrhein', 'Rheinland-Pfalz'],
          country: CountryCodes.DE,
          name: 'Mittelrhein',
        },
      },
    ],
  };
